import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Aboutas from "../Component/Aboutas/Aboutus"
const Aboutas1 = () => {
  return (
    <>
      <Nav />
      <Aboutas/>
      <Footer />
    </>
  );
};

export default Aboutas1;
