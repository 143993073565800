import React from 'react'


const Loader = () => {
  return (
   <>
   </>
  )
}

export default Loader