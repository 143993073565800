import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import MyProfile from "../Component/Profile/MyProfile";
const MyProfile1 = () => {
  return (
    <>
      <Nav />
      <MyProfile/>
      <Footer />
    </>
  );
};

export default MyProfile1;