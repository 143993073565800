import React from "react";
// import Footer from "../Common/Footer";
// import Nav from "../Common/Nav";
import Registration from "../Component/Authontication/Registration";
const Register = () => {
  return (
    <>
      {/* <Nav /> */}
      <Registration />
      {/* <Footer /> */}
    </>
  );
};

export default Register;