import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Contact from "../Component/Contact/Contact";
const Contact1 = () => {
  return (
    <>
      <Nav />
      <Contact/>
      <Footer />
    </>
  );
};

export default Contact1;