import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Termcondition from "../Component/Term/Termcondition";

const Termcondition1 = () => {
  return (
    <>
      <Nav />
      <Termcondition/>
      <Footer />
    </>
  );
};

export default Termcondition1;
