import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Category from "../Component/Category/Category";
const Categories = () => {
  return (
    <>
      <Nav />
      <Category/>
      <Footer />
    </>
  );
};

export default Categories;