import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Privacypolicy from "../Component/Privacy/Privacypolicy";
const Privacypolicy1 = () => {
  return (
    <>
     <Nav />
      <Privacypolicy/>
      <Footer />
    </>
  );
};

export default Privacypolicy1;
