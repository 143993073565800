import React from "react";
import Footer from "../Common/Footer";
import Nav from "../Common/Nav";
import Media from "../Component/Gallery/Media";
const Gallery = () => {
  return (
    <>
      <Nav />
      <Media/>
      <Footer />
    </>
  );
};

export default Gallery;