import React from "react";
// import Footer from "../Common/Footer";
// import Nav from "../Common/Nav";
import Logins from "../Component/Authontication/Logins";
const Login = () => {
  return (
    <>
      {/* <Nav /> */}
      <Logins/>
      {/* <Footer /> */}
    </>
  );
};

export default Login;