export const photos = [
    {
      src: "https://5.imimg.com/data5/PU/QC/MY-38700875/sarbati-wheat-500x500.jpg",
      width: 4,
      height: 3
     
    },
    {
      src: "https://t3.ftcdn.net/jpg/00/54/97/42/360_F_54974233_fRkwczNLCKaHaBprct7zkwoEJcFmhAgD.jpg",
      width: 5,
      height: 4
    },
    {
       src: "https://4.imimg.com/data4/IG/II/MY-27845820/bengal-gram-whole-500x500.jpg",
      width: 4,
      height: 4
    },
    {
      src: "https://i0.wp.com/post.medicalnewstoday.com/wp-content/uploads/sites/3/2022/04/chickpeas_closeup_1296x728_header-1024x575.jpg?w=1155&h=1528",
     width: 4,
     height: 3
   },
    {
      src: "https://t3.ftcdn.net/jpg/03/21/20/28/360_F_321202884_kuZNpXMNgQApiAnEGgNTwe0Bk4XLjB5K.jpg",
      width: 5,
      height: 4
    },
    
    {
       src: "https://upload.wikimedia.org/wikipedia/commons/0/07/Khyma_and_Basmati_rice.jpg",
      width: 5,
      height: 4
    },
    {
       src: "https://m.media-amazon.com/images/I/41r8vCZlTbL._AC_UF1000,1000_QL80_.jpg",
      width: 4,
      height: 3
    },
   
     
  ];
  